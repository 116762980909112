import channelsApi, { Channel } from '@/api/channels-api';
import scheduleApi from '@/api/schedule-api';
import storage, { LocalChannel } from '@/common/storage';
import { BackendDataType } from '@/store/BackendDataType';
import { StoreOptions } from 'vuex';

const SCHEDULE_INTERVAL_MILLISECONDS = 5 * 60 * 1000;
const DEFAULT_LOCAL_CHANNEL_ID = 701;
const DEFAULT_LOCAL_CHANNEL: LocalChannel = { localChannelSlug: 'stockholm', localChannelTitle: 'P4 Stockholm' };
const PRIORITY_CHANNEL_IDS = [132, 2562, 164];

let SCHEDULE_SERVICE_INTERVAL: any = null;

export default {
    namespaced: true,
    state: {
        _isInitialized: false,
        _isCookieInitialized: false,
        _channels: [],
        _schedules: [],

        selectedLocalChannelId: null,
        selectedAdditionalChannelId: null,
        activeSingleChannelId: null,
        isPrimaryChannelsMode: false,
        isSingleChannelMode: false
    },
    getters: {
        additionalChannels: state => state._channels.filter(c => c.type === 'additional'),
        localChannels: state => state._channels.filter(c => c.type === 'local'),
        primaryChannels(state, getters) {
            if (!state._channels.length) {
                return [];
            }
            const priorityChannels = state._channels.filter(c => c.type === 'priority');
            return priorityChannels.sort((a, b) => (a.title !== b.title ? (a.title < b.title ? -1 : 1) : 0));
        },
        schedule(state) {
            return (channelId: number) => {
                const channelSchedule = state._schedules.find(item => item.channelId === channelId);
                return channelSchedule ? channelSchedule.scheduleItems : [];
            };
        },
        channel(state) {
            return (channelId: number) => state._channels.find(item => item.id === channelId) || {};
        },
        selectedLocalChannel(state, getters) {
            return state._channels.find(c => c.type === 'local' && c.id === getters.localChannelId);
        },
        selectedAdditionalChannel(state, getters) {
            return state._channels.find(c => c.id === getters.additionalChannelId) ?? null;
        },
        channelsReady(state) {
            return state._channels.length > 0;
        },
        schedulesReady(state) {
            return state._schedules.length > 0;
        },
        readyForPresentation(state, getters) {
            return getters.channelsReady && getters.schedulesReady;
        },
        localChannelId(state) {
            return state.selectedLocalChannelId || DEFAULT_LOCAL_CHANNEL_ID;
        },
        additionalChannelId(state) {
            return state.selectedAdditionalChannelId;
        },
        scheduleServiceChannelIds(state, getters) {
            if (state.isPrimaryChannelsMode) {
                const channels = [...PRIORITY_CHANNEL_IDS, getters.localChannelId];
                return getters.selectedAdditionalChannel ? [...channels, getters.additionalChannelId] : channels;
            }
            if (state.activeSingleChannelId) {
                return [state.activeSingleChannelId];
            }
            return [];
        }
    },
    actions: {
        init(context) {
            if (context.state._isInitialized) {
                return;
            }

            context.commit('setSelectedAdditionalChannelId', storage.additionalChannel);
            context.commit('setIsCookieInitialized', true);

            if (context.state.isPrimaryChannelsMode) {
                context.dispatch('fetchSchedulesForChannels');
            }

            context.dispatch('fetchAllChannels');
        },
        async fetchAllChannels({ rootGetters, commit }) {
            let channels: Channel[] = rootGetters.backendData(BackendDataType.Channel);

            if (!channels) {
                channels = await channelsApi.allChannels();
            }

            commit('setChannels', channels);
        },
        startPollingAllChannels(context) {
            context.dispatch('fetchSchedulesForChannels', true);
            context.dispatch('startPollingSchedules');
        },
        startPollingPrimaryChannels(context) {
            context.commit('setIsPrimaryChannelsMode', true);
            if (context.state._isCookieInitialized) {
                context.dispatch('fetchSchedulesForChannels');
            }
            context.dispatch('startPollingSchedules');
        },
        startPollingSchedules(context) {
            context.commit('clearScheduleServiceInterval');

            SCHEDULE_SERVICE_INTERVAL = setInterval(() => {
                context.dispatch('fetchSchedulesForChannels');
            }, SCHEDULE_INTERVAL_MILLISECONDS);
        },
        fetchSchedulesForChannels({ commit, getters }, fetchAllChannels) {
            const request = fetchAllChannels ? {} : { ids: getters.scheduleServiceChannelIds };

            scheduleApi
                .get(request)
                .then(result => {
                    commit('setSchedules', result.data.schedules);
                })
                .catch(error => {
                    if (error.response?.status === 500 && SCHEDULE_SERVICE_INTERVAL) {
                        clearInterval(SCHEDULE_SERVICE_INTERVAL);
                    }
                });
        },
        setLocalChannel(context, localChannelId) {
            context.commit('setSelectedLocalChannelId', localChannelId);

            const channelInfo: Channel | undefined = context.state._channels.find(item => item.id === localChannelId);

            storage.localChannel = channelInfo
                ? {
                      localChannelSlug: channelInfo.url.replace(/\//g, ''),
                      localChannelTitle: channelInfo.title
                  }
                : DEFAULT_LOCAL_CHANNEL;

            if (localChannelId && context.state._isInitialized) {
                context.dispatch('fetchSchedulesForChannels');
            }
        },
        setAdditionalChannel(context, additionalChannelId) {
            context.commit('setSelectedAdditionalChannelId', additionalChannelId);
            storage.additionalChannel = additionalChannelId;

            if (additionalChannelId && context.state._isInitialized) {
                context.dispatch('fetchSchedulesForChannels');
            }
        },
        unmount(context) {
            context.commit('setIsPrimaryChannelsMode', false);
            context.commit('setIsSingleChannelMode', false);
            context.commit('setActiveSingleChannelId', null);
            context.commit('clearScheduleServiceInterval');
        }
    },
    mutations: {
        setIsInitialized(state, isInitialized) {
            state._isInitialized = isInitialized;
        },
        setIsCookieInitialized(state, isCookieInitialized) {
            state._isCookieInitialized = isCookieInitialized;
        },
        setChannels(state, channels) {
            state._channels = channels;

            let selectedLocalChannel = state._channels.find(item =>
                item.url.includes(storage.localChannel?.localChannelSlug ?? DEFAULT_LOCAL_CHANNEL.localChannelSlug)
            );

            // if using legacy local channel local storage type
            if (typeof storage.localChannel === 'number') {
                selectedLocalChannel = state._channels.find(item => item.id === Number(storage.localChannel));
                if (selectedLocalChannel) {
                    storage.localChannel = {
                        localChannelSlug: selectedLocalChannel.url.replace(/\//g, ''),
                        localChannelTitle: selectedLocalChannel.title
                    };
                }
            }

            state.selectedLocalChannelId = selectedLocalChannel?.id ?? null;
            state._isInitialized = true;
        },
        setSchedules(state, schedules) {
            state._schedules = schedules;
        },
        setSelectedLocalChannelId(state, localChannelId) {
            state.selectedLocalChannelId = localChannelId;
        },
        setSelectedAdditionalChannelId(state, additionalChannelId) {
            state.selectedAdditionalChannelId = additionalChannelId;
        },
        setIsPrimaryChannelsMode(state, mode) {
            state.isPrimaryChannelsMode = mode;
        },
        setIsSingleChannelMode(state, mode) {
            state.isSingleChannelMode = mode;
        },
        setActiveSingleChannelId(state, activeSingleChannelId) {
            state.activeSingleChannelId = activeSingleChannelId;
        },
        clearScheduleServiceInterval() {
            clearInterval(SCHEDULE_SERVICE_INTERVAL);
        }
    }
} as StoreOptions<State>;

type State = {
    _isInitialized: boolean;
    _isCookieInitialized: boolean;
    _channels: Channel[];
    _schedules: any[];
    selectedLocalChannelId: number | null;
    selectedAdditionalChannelId: number | null;
    activeSingleChannelId: number | null;
    isPrimaryChannelsMode: boolean;
    isSingleChannelMode: boolean;
};
