import playerMessages from '@/enums/player-messages';
import icecastStore from '@/store/icecast-store';
import hlsStore from '@/store/hls-store';
import playerHlsToggler from '@/common/player-hls-toggler';
import playerScheduleHelper from '@/common/player-schedule-helper';
import playerStates from '@/enums/player-states';
import storage from '@/common/storage';
import playerApi from '@/api/player-api';
import { throttledErrorLogger } from '@/logging/ThrottledErrorLogger';

let metaDataInterval;
const metaDataIntervalMiliseconds = 60 * 1000;

export default {
    namespaced: true,
    state: {
        isActive: false,
        id: 0,
        type: '',
        publicationId: 0,
        channelTheme: '',
        isHlsEnabled: false,
        scheduleItems: [],
        songs: [],
        experiment: '',
        clientIdHash: ''
    },
    getters: {
        isActive: state => state.isActive,
        experiment: function (state) {
            return state.experiment;
        },
        title: function (state, getters) {
            return getters.currentScheduleItem.title || '';
        },
        subtitle: function (state, getters) {
            return getters.currentScheduleItem.subtitle || '';
        },
        programId: function (state, getters) {
            return getters.currentScheduleItem.programid || '';
        },
        description: function (state, getters) {
            return getters.currentScheduleItem.description || '';
        },
        readMoreUrl: function (state, getters) {
            return getters.currentScheduleItem.readmoreurl || '';
        },
        displayImageUrl: function (state, getters) {
            return getters.currentScheduleItem.displayimageurl || '';
        },
        episodeStartTime: function (state, getters) {
            const dateTime = getters.currentScheduleItem.starttime || null;
            if (!dateTime) {
                return new Date(0);
            }
            return new Date(dateTime);
        },
        previousEpisodeStartTime: function (state, getters) {
            const dateTime = getters.previousScheduleItem.starttime || null;
            if (!dateTime) {
                return new Date(0);
            }
            return new Date(dateTime);
        },
        episodeEndTime: function (state, getters) {
            const dateTime = getters.currentScheduleItem.endtime || null;
            if (!dateTime) {
                return new Date(0);
            }
            return new Date(dateTime);
        },
        currentTime: function (state, getters, rootState) {
            const time = state.isHlsEnabled
                ? rootState.player.live.hls.streamPositionTime || new Date()
                : rootState.player.live.icecast.currentTime;
            return convertToRealTime(time, getters.audioDelayMilliseconds);
        },
        currentScheduleItem: function (state, getters) {
            return playerScheduleHelper.getItemByTime(state.scheduleItems, getters.currentTime);
        },
        previousScheduleItem: function (state, getters) {
            return playerScheduleHelper.getPreviousItemByTime(state.scheduleItems, getters.currentTime);
        },
        livePositionTime: function (state, getters, rootState) {
            const time = state.isHlsEnabled
                ? rootState.player.live.hls.livePositionTime || new Date()
                : rootState.player.live.icecast.currentTime;
            return convertToRealTime(time, getters.audioDelayMilliseconds);
        },
        audioDelayMilliseconds: function (state, getters, rootState) {
            return state.isHlsEnabled
                ? rootState.player.live.hls.audioDelayMilliseconds
                : rootState.player.live.icecast.audioDelayMilliseconds;
        },
        currentSong: function (state, getters) {
            if (state.songs === null || state.songs.length === 0) {
                return null;
            }
            return playerScheduleHelper.getChannelSong(state.songs, getters.currentTime);
        },
        statusMessage: function (state, getters, rootState) {
            if (state.isHlsEnabled) {
                return rootState.player.live.hls.statusMessage;
            }
            return rootState.player.live.icecast.statusMessage;
        },
        playerMessage: function (state, getters, rootState) {
            if (state.isHlsEnabled) {
                return rootState.player.live.hls.playerMessage;
            }
            return rootState.player.live.icecast.playerMessage;
        },
        playerState: function (state, getters, rootState) {
            if (state.isHlsEnabled) {
                return rootState.player.live.hls.playerState;
            }
            return rootState.player.live.icecast.playerState;
        },
        isInFatalErrorState: function (state, getters, rootState) {
            if (state.isHlsEnabled) {
                return rootState.player.live.hls.playerState === playerStates.ERROR;
            }
            return rootState.player.live.icecast.playerState === playerStates.ERROR;
        },
        audioPlaying: function (state, getters) {
            return getters.playerState === playerStates.PLAYING ? { id: state.id, type: state.type } : null;
        },
        isHlsEnabled: state => state.isHlsEnabled,
        channelTheme: state => state.channelTheme,
        scheduleItems: state => state.scheduleItems,
        id: state => state.id,
        type: state => state.type,
        clientIdHash: state => state.clientIdHash
    },
    actions: {
        init: async function (context) {
            context.commit('setIsHlsEnabled', await playerHlsToggler.hasHlsSupportAsync());
            context.dispatch('player/live/hls/init', null, { root: true });
            context.dispatch('player/live/icecast/init', null, { root: true });
        },
        play: async function (context, audio) {
            context.commit('setIsActive', true);
            context.dispatch('saveState', audio);
            context.dispatch('metadata/clear', null, { root: true });
            context.dispatch('audio/pause', null, { root: true });

            await loadMetadata(audio.id, audio.type, audio.publicationId)
                .then(function (response) {
                    metadataLoaded(context, response.data);
                    context.commit('setPublicationId', audio.publicationId);
                    context.commit('setExperiment', audio.id);
                    if (context.state.isHlsEnabled) {
                        context.dispatch('player/live/hls/trackPlay', audio, { root: true });
                    } else {
                        context.dispatch('player/live/icecast/trackPlay', audio, { root: true });
                    }
                })
                .catch(function (error) {
                    metadataFailed(context, audio.id, audio.type, error);
                });

            if (context.state.isHlsEnabled) {
                context.dispatch('player/live/hls/play', audio, { root: true });
            } else {
                context.dispatch('player/live/icecast/play', audio, { root: true });
            }

            startPollMetadata(context, audio.id, audio.type, audio.publicationId);
        },
        resume: function (context) {
            loadAndSetMetadata(context, context.state.id, context.state.type, context.state.publicationId);
            startPollMetadata(context, context.state.id, context.state.type, context.state.publicationId);
            if (context.state.isHlsEnabled) {
                context.dispatch('player/live/hls/resume', null, { root: true });
            } else {
                context.dispatch('player/live/icecast/resume', null, { root: true });
            }
        },
        restoreState: function (context, audio) {
            context.commit('setIsActive', true);

            const audioId = audio ? audio.id : storage.audioId;
            const audioType = audio ? audio.type : storage.audioType;

            loadAndSetMetadata(context, audioId, audioType, 0);

            if (context.state.isHlsEnabled) {
                context.dispatch('player/live/hls/restoreState', null, { root: true });
            } else {
                context.dispatch('player/live/icecast/restoreState', null, { root: true });
            }
        },
        pause: function (context) {
            context.dispatch('player/live/icecast/stop', null, { root: true });
            context.dispatch('player/live/hls/pause', null, { root: true });
            stopPollMetadata();
        },
        setVolume: function (context, volume) {
            context.dispatch('player/live/icecast/setVolume', volume, { root: true });
            context.dispatch('player/live/hls/setVolume', volume, { root: true });
        },
        deactivate: function (context) {
            context.commit('setIsActive', false);
            context.dispatch('player/live/icecast/deactivate', null, { root: true });
            context.dispatch('player/live/hls/deactivate', null, { root: true });
            context.dispatch('clearMetadata');
            context.commit('setPublicationId', 0);
            stopPollMetadata();
        },
        unload: function (context) {
            context.dispatch('player/live/icecast/unload', null, { root: true });
            context.dispatch('player/live/hls/unload', null, { root: true });
            stopPollMetadata();
        },
        clearMetadata: function (context) {
            context.commit('setMetadata', {});
        },
        saveState: function (context, audio) {
            storage.audioType = audio.type;
            storage.publicationId = audio.publicationId;

            storage.playerState = {
                channel: { id: audio.id },
                audio: { type: audio.type === 'music' ? 'channel' : audio.type }
            };
        },
        onQualityChangeAsync: async function (context) {
            context.dispatch('player/live/icecast/onQualityChange', null, { root: true });
            await context.dispatch('player/live/hls/onQualityChangeAsync', null, { root: true });
        }
    },
    mutations: {
        setIsActive: function (state, isActive) {
            state.isActive = isActive;
        },
        setMetadata: function (state, metadata) {
            state.id = metadata.id || 0;
            state.type = metadata.type || '';
            state.channelTheme = metadata.channelTheme || '';
            state.scheduleItems = metadata.scheduleItems || [];
            state.songs = metadata.songs || [];
            state.clientIdHash = metadata.clientIdHash || '';
        },
        setIsHlsEnabled: function (state, isHlsEnabled) {
            state.isHlsEnabled = isHlsEnabled;
        },
        setPublicationId: function (state, publicationId) {
            state.publicationId = publicationId;
        },
        setExperiment: function (state, value) {
            state.experiment = value;
        }
    },
    modules: {
        icecast: icecastStore,
        hls: hlsStore
    }
};

function startPollMetadata(context, id, type, publicationId) {
    stopPollMetadata();
    metaDataInterval = setInterval(function () {
        if (context.getters.isInFatalErrorState) {
            stopPollMetadata();
            return;
        }
        loadAndSetMetadata(context, id, type, publicationId);
    }, metaDataIntervalMiliseconds);
}

function stopPollMetadata() {
    clearInterval(metaDataInterval);
}

function loadAndSetMetadata(context, id, type, publicationId) {
    loadMetadata(id, type, publicationId)
        .then(function (response) {
            metadataLoaded(context, response.data);
        })
        .catch(function (error) {
            metadataFailed(context, id, type, error);
        });
}

function loadMetadata(id, type, publicationId) {
    const options = {
        id: id,
        type: type,
        publicationId: publicationId
    };

    if (!options.id || !options.type) {
        throttledErrorLogger.logError({
            message: 'Invalid options passed to loadMetadata',
            data: options
        });
    }

    return playerApi.getAudioMetadata(options);
}

function metadataLoaded(context, data) {
    const metadata = {
        id: data.id,
        type: data.audiotype,
        clientIdHash: data.clientIdHash
    };

    if (data.items.length > 0) {
        metadata.channelTheme = data.channelTheme;
        metadata.scheduleItems = data.items;
        metadata.songs = data.currentSongs;
    }

    context.commit('setMetadata', metadata);
}

function metadataFailed(context, id, type, error) {
    const statusCode = error.request ? error.request.status : undefined;
    let errorMessage;
    if (statusCode === 404) {
        errorMessage = playerMessages.METADATA_NOT_FOUND;
    } else if (statusCode === 0) {
        errorMessage = playerMessages.NETWORK_ERROR;
    } else {
        errorMessage = playerMessages.METADATA_INTERNAL_ERROR;
    }

    const metadata = {
        id: id,
        type: type
    };

    context.commit('setMetadata', metadata);
}

function convertToRealTime(dateTime, streamDelayMilliseconds) {
    return new Date(dateTime.getTime() - streamDelayMilliseconds);
}
